<template>
  <section id="home" class="container-fluid">
    <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item " :class="{active: index == 3}" v-for="(slide, index) in slider" :key="index">
          <img class="d-block w-100 slider" :src="slide.img" alt="">
          <p class="words">..<span>{{ slide.word1 }}</span> {{ slide.word2 }} </p>
        </div>
        <!-- https://madewithvuejs.com/vueflux -->

      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying"
        data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying"
        data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
      
    </div>
  </section>
</template>
<script>
export default {
  name: 'HomeOne',
  data() {
    return {
      
      slider: [
        {
          img: require('../assets/slide2.png'),
          word1: 'مجلى',
          word2: 'أنيق'
        },
        {
          img: require('../assets/slide.png'),
          word1: 'لمسات الطبيعة',
          word2: 'في الغرانيت'
        },
        {
          img: require('../assets/slide3.png'),
          word1: 'سيراميك',
          word2: 'عصري'
        },
        {
          img: require('../assets/slide4.jpg'),
        },
      ]
    }
  },
  methods: {
    looping() {

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@500&family=Kufam:wght@400;600&family=Oregano&family=Quicksand:wght@300;400;500;600;700&family=Rakkas&family=Roboto:wght@300;400;500;700;900&family=Ubuntu&display=swap');
@import url('https://fonts.googleapis.com/css2?family=El+Messiri&family=Exo+2:wght@500&family=Harmattan&family=Kufam:wght@400;600&family=Lemonada&family=Mada:wght@200..900&family=Oregano&family=Quicksand:wght@300;400;500;600;700&family=Rakkas&family=Roboto:wght@300;400;500;700;900&family=Rubik:ital,wght@0,300..900;1,300..900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

body {
  margin: 0 !important;
}

*,
::after,
::before {
  box-sizing: unset;
}


.carousel-item {
  width: 100%;
  height: 100vh;

}

.carousel-inner {
  height: 100vh;
}

.slider {
  position: relative;
  object-fit: cover;
  width: 100%;
  height: 100%;
  margin-top: 30px;
}

.container-fluid {
  --bs-gutter-x: 0;
}

.carousel-inner .carousel-item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  z-index: 1;
  opacity: 0.1;
}

.slogan {
  position: absolute;
    width: 180px;
    top: 20%;
    left: 10%;
    height: 69px;
}

.words {
  position: absolute;
  z-index: 1;
  top: 69%;
  right: 12%;
  font-size: 50px;
  font-family: "Mada", sans-serif;
  color: #fff;
}

.words span {
  color: #353c4a;
}

.loop.active {
  display: block !important;
}
@media (max-width: 820px) {
  .carousel-inner {
  height: 70vh;
}
.carousel-item {
  width: 100%;
  height: 70vh;
}
.words{
  font-size: 30px;
}
.slogan{
  width: 160px;
  height: 60px;
}
}
@media (max-width: 412px) {
  .carousel-inner {
  height: 50vh;
}
.carousel-item {
  width: 100%;
  height: 50vh;

}
.words{
  font-size: 25px;
}
.slogan{
  width: 153px;
  height: 50px;
  top: 30%
}
}
</style>
