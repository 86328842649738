<template>
  <div class="home">
    <Navbar></Navbar>
    <Home></Home>
    <Products></Products>
    <About></About>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue';
import Home from '@/components/Home.vue';
import Products from '@/components/products.vue';
import About from '@/components/About.vue';
import Footer from '@/components/footer.vue';
export default {
  name: 'HomeView',
  components: {
    Navbar,
    Home,
    Products,
    About,
    Footer
    
  }
}
</script>
