<template>
    <section id="products">
        <h2 class="p-2">المنتجات</h2>
        <div class="row">
            
            <div class="product mb-2 p-3 col-lg-4 col-md-6 col-sm-12" v-for="(prod, index) in products" :key="index">
                <RouterLink class="link" :to="prod.path">
                    <img class="imgs " :src="prod.img" alt="">
                    <p class="text mt-3">{{ prod.text }}</p>
                </RouterLink>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'products-p',
    data() {
        return {
            products: [
                {
                    img: require('../assets/product1.jpg'),
                    text: "سيراميك",
                    path: "/seraMic"
                },
                {
                    img: require('../assets/product2.jpeg'),
                    text: "بورسلان",
                    path: "/borSalan"
                },
                {
                    img: require('../assets/product3.jpg'),
                    text: "اكسسوارات حمام",
                    path: "/Access-H"
                },
                {
                    img: require('../assets/product4.jpg'),
                    text: "أحواض المجلى",
                    path: "/buf-fet"
                },
                {
                    img: require('../assets/product5.jpg'),
                    text: "صحية",
                    path: "/sani-tary"
                },
                {
                    img: require('../assets/product6.jpg'),
                    text: "طوب",
                    path: "/toup-q"
                },
                {
                    img: require('../assets/product7.jpg'),
                    text: "اكسسوارات سيراميك",
                    path: "/x-sera"
                },
                {
                    img: require('../assets/product8.jpg'),
                    text: "سجاد",
                    path: "/s-sejad"
                }

            ]
        }
    },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=El+Messiri&family=Exo+2:wght@500&family=Kufam:wght@400;600&family=Oregano&family=Quicksand:wght@300;400;500;600;700&family=Rakkas&family=Roboto:wght@300;400;500;700;900&family=Ubuntu&display=swap');

section {
    background-image: linear-gradient(220.55deg, #bcbcbc 0%, #ffffff86 100%);
    
}

.product {
    position: relative;
    height: 220px;
    transition: .4s;
}

.product:hover {
    background-color: #ffffff86;
    
}
.arr{
  width: 50px;
  height: 50px;
}
h2 {
    font-family: "El Messiri", sans-serif;
    font-size: 35px;
    position: relative;
}
h2:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 1px;
    background: #363636;
    width: 61px;
    margin: 0 -30px;
}
.imgs {
    width: 215px;
    height: 160px;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;}
.imgs::before{
    content: "";
    position: absolute;
    top: 17%;
    right: 5px;
    width: 40px;
    height: 70%;
    background: #363636;
}

.text {
    font-family: "El Messiri", sans-serif;
    font-size: 20px;
}
.link{
    text-decoration: none;
    color: #363636
}
.row {
    margin-left: 0 !important;

    --bs-gutter-x: 0rem !important;
}
</style>