<template>
    <section id="about">
        <div class="about-section wrapper">
            <div class="about-section wrapper">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="text col-lg-6 col-md-12 p-4 text-sec">
                            <h2>من نحن؟</h2>
                            <p>وكلاء مستوردون لمواد الإكساء والسيراميك والبورسلان والصحية ؛ يتركز عملنا في جميع مدن
                                الشمال السوري عن طريق شبكة واسعة من الشركاء والعملاء ؛ نسعى لتقديم تشكيلة واسعة من أصناف
                                السيراميك و الحمامات والأدوات الصحية لاسواقنا وعملائنا بجودة مضمونة وأسعار مناسبة</p>
                            <a href="" class="butn">لمعرفة المزيد</a>
                        </div>
                        <div class="col-lg-6 col-md-12 mb-lg-0 mb-5">
                            <div class="card bee border-0 p-4">
                                <img :src="img" class="img-fluid">
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'aboutPage',
    data() {
        return {
            img: require('../assets/about.jpeg')
        }
    },
}
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Marhey:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@500&family=Kufam:wght@400;600&family=Oregano&family=Quicksand:wght@300;400;500;600;700&family=Rakkas&family=Roboto:wght@300;400;500;700;900&family=Ubuntu&display=swap');
@import url('https://fonts.googleapis.com/css2?family=El+Messiri&family=Exo+2:wght@500&family=Kufam:wght@400;600&family=Oregano&family=Quicksand:wght@300;400;500;600;700&family=Rakkas&family=Roboto:wght@300;400;500;700;900&family=Ubuntu&display=swap');

section {
    background-color: #fff;
}

.text p {
    line-height: 45px;
    top: 20%;
    padding: 20px;
    font-size: 16px;
    font-family: "Marhey", sans-serif;
}

.text h2 {
    font-family: "El Messiri", sans-serif;
    font-size: 31px;
    padding: 10px;
    position: relative;
}
// .img-fluid{
//     box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
// }
h2:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 1px;
    background: #363636;
    width: 61px;
    margin: 0 -30px;
}

.butn {
    color: #fff;
    display: inline-block;
    background: #363636;
    text-decoration: none;
    font-size: 19px;
    text-transform: uppercase;
    text-align: center;
    top: 65%;
    padding: 13px 40px 8px;
    position: relative;
    font-family: 'El Messiri', sans-serif;
    border: #363636 solid 0.5px;
    transition: 0.4s;
}

.butn:hover {
    color: #363636;
    background: #fff;

}
.img-fluid{
    z-index: 10;
}
.bee{
    position: relative;
}
.bee::after{
    content: "";
    position: absolute;
    top: 17%;
    right: 5px;
    width: 40px;
    height: 70%;
    background: #363636;
}

</style>