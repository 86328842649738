import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'home', component: HomeView },
  { path: '/seraMic', name: 'sera', component: () => import('@/views/Seramic.vue')},
  { path: '/borSalan', name: 'bors', component: () => import('@/views/Borsalan.vue') },
  { path: '/Access-H', name: 'AccH', component: () => import('@/views/accessH.vue') },
  { path: '/buf-fet', name: 'buffet', component: () => import('@/views/buffet.vue') },
  { path: '/sani-tary', name: 'sanit', component: () => import('@/views/sanitary.vue') },
  { path: '/toup-q', name: 'toup', component: () => import('@/views/Toup.vue') },
  { path: '/x-sera', name: 'xser', component: () => import('@/views/Xseramic.vue') },
  { path: '/s-sejad', name: 'sejad', component: () => import('@/views/Sejad.vue') }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.afterEach(async(to) => {
  if (to.hash) {
    setTimeout(() => {
      const el = document.getElementById(to.hash.slice(1));
      if (el) { // التحقق من وجود العنصر قبل استخدام scrollIntoView
        el.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
      }
    }, 200);
  }
});

// router.beforeEach((to, from, next)=>{
//   document.title=`${to.name}`
//   next()
// })
export default router
