<template>
  <nav class="navbar navbar-expand-lg bg-body-tertiary">
    <div class="container-fluid">
      <div class="burger-icon" v-if="icon" :class="{ 'active': showMenu }" @click.prevent="toggleMenu">
        <div class="line line1"></div>
        <div class="line line2"></div>
        <div class="line line3"></div>
      </div>
      <div class="dropdown" v-if="marks">
        <a class="btn btn-secondary dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
          aria-expanded="false">
          الماركات
        </a>
       <div>
        <ul class="dropdown-menu">
          <li  v-for="(abd) in images" :key="abd.id" ><a class="dropdown-item" ><img class="marka" :src="abd.url"> </a></li>
        </ul>
       </div>
      </div>
      <div class="big" v-if="hidden">
        <div class="logos  col-lg-6   " v-for="(image, index) in images" :key="index">
          <a><img class="loop" :src="image.url"></a>
        </div>
      </div>
      <div class="collapse navbar-collapse" v-if="pages" id="navbarNavAltMarkup">
        <div class="navbar-nav " dir="rtl">
          <RouterLink class="nav-link" @click="toggleMenu2" to="/#products">المنتجات</RouterLink>
          <RouterLink class="nav-link" @click="toggleMenu2" aria-current="page" to="/#about">من نحن</RouterLink>
          <RouterLink class="nav-link" @click="toggleMenu2" to="/#footer">للتواصل</RouterLink>
        </div>

      </div>

      <div class="menu">
        <ul class="dropdown-nav" v-show="icon" v-if="showMenu">
          <RouterLink class="nav-link1" to="/#products">المنتجات</RouterLink>
          <RouterLink class="nav-link1" aria-current="page" to="/#about">من نحن</RouterLink>
          <RouterLink class="nav-link1" to="/#footer">للتواصل</RouterLink>
        </ul>
      </div>

      <a class="navbar-brand brand" href="#home"><img src="../assets/bader2.png" alt=""></a>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {
      img: require('../assets/bader.png'),
      showMenu: false,
      icon: false,
      hidden: true,
      marks: false,
      pages: true,
      images: [
        { url: require('../assets/dura.png')},
        { url: require('../assets/isiklar.png')},
        { url: require('../assets/yuksel.png') },
        { url: require('../assets/nova.png') },
        { url: require('../assets/turkuaz.png') },
        { url: require('../assets/avrasya.png') },
        { url: require('../assets/erciyes2.png') },
      ],

    }
  },
  created() {
    window.addEventListener("resize", this.scrolling);
    this.scrolling();
    window.addEventListener("resize", this.scrollingTwo);
    this.scrollingTwo();
  },
  mounted() {
        document.addEventListener('click', this.handleClickOutside);
    },
  methods: {
    toggleMenu(event) {
      event.stopPropagation()
      this.showMenu = !this.showMenu;
    },
    toggleMenu2() {
      this.showMenu = false;
    },
    handleClickOutside() {
            const navMenu = document.querySelector('.burger-icon');
            if (navMenu && this.showMenu) {
                this.showMenu = !this.showMenu
            }
        },
    scrolling() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 1150) {
        this.icon = true;
        this.pages = false;
        return
      }
      this.icon = false;
      this.pages = true;
      return
    },
    scrollingTwo() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 769) {
        this.marks = true;
        this.hidden = false;
        return
      }
      this.marks = false;
      this.hidden = true;
      return
    },
  }
}
</script>
<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@500&family=Kufam:wght@400;600&family=Oregano&family=Quicksand:wght@300;400;500;600;700&family=Rakkas&family=Roboto:wght@300;400;500;700;900&family=Ubuntu&display=swap');
@import url('https://fonts.googleapis.com/css2?family=El+Messiri&family=Exo+2:wght@500&family=Kufam:wght@400;600&family=Oregano&family=Quicksand:wght@300;400;500;600;700&family=Rakkas&family=Roboto:wght@300;400;500;700;900&family=Ubuntu&display=swap');

.nav-link {
  font-size: 15px !important;
  font-weight: 600 !important;
  font-family: 'Kufam', sans-serif;
  color: #3d597d;
}

.navbar .container-fluid {
  justify-content: space-evenly !important;
}

.navbar-collapse {
  flex-grow: 0 !important;
}

.navbar {
  --bs-navbar-padding-y: 0rem !important;
  position: fixed;
  z-index: 1000;
  width: 100%;
}

nav {
  img {
    height: 80px;
    transition: 0.5s ease all;
  }
}


.burger-icon {
  width: 30px;
  height: 20px;
  cursor: pointer;
}

.line {
  width: 25px;
  height: 3px;
  background-color: #384151;
  margin: 5px 0;
  transition: 0.3s;
}

.line2 {
  width: 15px;
}


.active .line1 {
  transform: rotate(45deg) translate(5px, 6px);
}

.active .line2 {
  opacity: 0;
}

.active .line3 {
  transform: rotate(-45deg) translate(5px, -6px);
}

.dropdown-nav {
  position: fixed;
  width: 100%;
  max-width: 180px;
  color: #fff;
  background-color: #697585;
  border-radius: 0 10px 10px 0;
  transition: 0.5s;
  z-index: 3000;
  left: -5px;


}

.marka {
  width: 61px;
  height: 33px;
}

dl,
ol,
ul {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.nav-link1 {
  font-weight: 500;
  font-size: 14px;
  font-family: 'El Messiri', sans-serif;
  display: flex;
  color: #fff;
  justify-content: center;
  margin: 20px;
  direction: rtl;
  text-decoration: none;


  &:hover {
    cursor: pointer;
    transition: 0.3s;
    box-shadow: 2px #3d597d;
    box-shadow: 0px 2px 10px -1px #fff;
  }
}

.loop {
  width: 68px;
  height: 35px;
  padding-left: 12px;
}

.big {
  display: flex;
}

.col-lg-6 {
  width: 15% !important;
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  z-index: 3000;
  margin-top: var(--bs-dropdown-spacer);
}

@media (max-width: 1150px) {
  .burger-icon {
    margin-bottom: 10px;
  }
}

@media (max-width: 992px) {
  .navbar .container-fluid {
    justify-content: space-between !important;
  }
}
</style>
