<template>
    <section id="footer">
      <footer class=" main py-5">
        <div class="row">
          <div class="col-12 col-sm-6 col-md-3 mb-3">
            <ul class="nav flex-column impo">
              <li class="nav-item mb-2">
                <h5 class="one">روابط هامة</h5>
              </li>
              <li class="nav-item mb-2"><a href="#home" class="nav-link p-0 text-body-secondary">الرئيسية</a></li>
              <li class="nav-item mb-2"><a href="#about" class="nav-link p-0 text-body-secondary">من نحن؟</a>
              </li>
              <li class="nav-item mb-2"><a href="#products" class="nav-link p-0 text-body-secondary">المنتجات</a></li>
            </ul>
          </div>
  
          <div class="col-12 col-sm-6 col-md-3 mb-3">
  
            <ul class="nav flex-column text-align-center impo">
              <li class="nav-item mb-2  ">
                <h5 class="two">تواصل معنا</h5>
              </li>
              <li class="nav-item mb-2"><a href="https://api.whatsapp.com/send/?phone=905360220091&text&type=phone_number&app_absent=0" class="nav-link p-0 text-body-secondary">+905360220091 <font-awesome-icon
                    icon="phone" /></a></li>
              <li class="nav-item mb-2"><a href="mailto:bilal@bedir.biz" class="nav-link p-0 text-body-secondary">bilal@bedir.biz
                  <font-awesome-icon icon="at" /></a></li>
              <li class="nav-item mb-2"><a
                  href="https://www.google.com/maps/place/%D9%85%D8%B1%D9%83%D8%B2+%D8%A8%D8%AF%D8%B1.%D9%84%D8%AA%D8%AC%D8%A7%D8%B1%D8%A9+%D8%A7%D9%84%D8%B3%D9%8A%D8%B1%D8%A7%D9%85%D9%8A%D9%83%E2%80%AD/@36.3795561,37.5141836,19.27z/data=!4m6!3m5!1s0x15303d36a210a7e3:0x69e9dfc02ee32a68!8m2!3d36.3795588!4d37.5131014!16s%2Fg%2F11nxv80xlk?entry=ttu"
                  class="nav-link p-0 text-body-secondary">المحلق الشمالي _ شرق دوار الراعي <font-awesome-icon
                    icon="location-dot" /></a></li>
  
            </ul>
          </div>
          <div class=" enter col-md-4 offset-md-1 mb-3">
            <img src="../assets/bader2.png" alt="">
          </div>
        </div>
        <ul class="list-unstyled d-flex">
            <li class="ms-3"><a class="link-body-emphasis" href="https://api.whatsapp.com/send/?phone=905360220091&text&type=phone_number&app_absent=0"><font-awesome-icon class="icons"
                  icon="fa-brands fa-whatsapp" /></a></li>
            <li class="ms-3"><a class="link-body-emphasis"
                href="https://www.instagram.com/bader_trading?igsh=MW15bmN6d21qYzJnZQ=="><font-awesome-icon class="icons"
                  icon="fa-brands fa-instagram" /></a></li>
            <li class="ms-3"><a class="link-body-emphasis"
                href="https://www.facebook.com/bader.co.sy?mibextid=ZbWKwL"><font-awesome-icon class="icons"
                  icon="fa-brands fa-facebook" /></a></li>
            <li class="ms-3"><a class="link-body-emphasis" href="https://t.me/baderco"><font-awesome-icon class="icons"
                  icon="fa-brands fa-telegram" /></a></li>
          </ul>
        <div class=" d-flex flex-column flex-sm-row justify-content-between border-top">
          <p><a class="face" href="https://www.facebook.com/profile.php?id=100056766375556&mibextid=ZbWKwL"> Abdullah-Nasser  </a> شركة بدر التجارية جميع الحقوق محفوظة 2024 © تطوير</p>
          
        </div>
      </footer>
    </section>
  </template>
  
  <script>
  export default {
    name:'footerPage',
    data() {
      return {
  
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=El+Messiri&family=Exo+2:wght@500&family=Kufam:wght@400;600&family=Oregano&family=Quicksand:wght@300;400;500;600;700&family=Rakkas&family=Roboto:wght@300;400;500;700;900&family=Ubuntu&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@500&family=Oregano&family=Quicksand:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700;900&family=Ubuntu&display=swap');
section{
  position: relative;
  background-image: linear-gradient(220.55deg, #ffffff86 0%, #bcbcbc 100%);

}
  .main {
  
    color: #353c4a;
  }
  
  .flex-column {
    flex-direction: column !important;
    text-align: right;
    display: inline-flex;
    line-height: 40px;
  }
  
  h5 {
    font-size: 20px;
    font-family: 'El Messiri', sans-serif;
  }
  
  .two {
    left: 100px;
  }
  
  p {
    display: flex;
    justify-content: center;
    font-size: 15px;
    color: #353c4a;
    font-family: 'El Messiri', sans-serif;
  }
  
  .text-body-secondary {
    font-family: 'El Messiri', sans-serif !important;
  }
  
  .text-body-secondary:hover {
    color: #353c4a !important;
  }
  
  .icons {
    width: 30px;
    height: 30px;
    color: #353c4aa9;
    transition: .5s;
  }
  
  .icons:hover {
    color: #353c4a;
  }
  
  .face {
    text-decoration: none;
    font-family: 'Oregano', cursive;
    color: #353c4ad8;
    transition: .5s;
  }
  
  .face:hover {
    color: #353c4a;
  }
  
  .py-5 {
    padding-bottom: 0rem !important;
  }
  .enter img{
    width: 330px;
  }
  .text-body-secondary {
    --bs-text-opacity: 1;
    color: rgb(33 37 41 / 57%) !important;
}
.row {
  --bs-gutter-x: 0rem;

    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1* var(--bs-gutter-y));
    margin-right: calc(-.5* var(--bs-gutter-x));
    margin-left: 0;
}
.d-flex {
    display: flex !important;
    justify-content: center;
}
@media (max-width: 767px) {
  .enter img{
    width: 250px;
  }
  h5 {
    font-size: 17px;
    font-family: 'El Messiri', sans-serif;
  }
  .row {
    --bs-gutter-x: 0rem;
  }
  .row[data-v-6c4d8baa] {
    --bs-gutter-x: 0rem;
}
.impo{
  text-align: center;

}
}
@media (max-width: 500px) {
  .text-body-secondary{
    font-size: 12px;
  }
  p{
    font-size: 13px;
  }
  .flex-column{
    padding-right: 10px;
  }
}
  </style>